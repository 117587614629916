const generateCompanyObject = company => ({
  id: company.id,
  name: company.name,
  isOwner: company.is_owner,
  notifications: company.notifications,
  subscriptionType: company.subscription_type || {},
  functionalityPackages: company.functionality_packages || {},
  isFullAccess: company.is_full_access,
  isSecurity: company.is_security,
  turnOnDirectoryCode: company.turn_on_directory_code,
  pstnRollOverAfterMobileApp: company.pstn_roll_over_after_mobile_application,
  directoryCodeLength: company.directory_code_length,
});

export default generateCompanyObject;
