import React, {useEffect} from 'react';
import { TextField, Button } from '@material-ui/core';

import IntlMessages from 'util/IntlMessages';
import { useSelector } from 'react-redux';

export const Form = (props) => {
  const {
    values: { email },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    validateForm
  } = props;

  const locale = useSelector(state => state.auth.locale);

  useEffect(() => {
    validateForm();
  }, [validateForm, locale]);


  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <TextField
          id="email"
          name="email"
          helperText={touched.email ? errors.email : ''}
          error={touched.email && Boolean(errors.email)}
          label={<IntlMessages id="appModule.email" />}
          fullWidth
          value={email}
          onChange={change.bind(null, 'email')}
          margin="normal"
          className="mt-1 my-sm-3"
        />
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}>
            <IntlMessages id="appModule.resetPasswordButton" />
          </Button>
        </div>
      </fieldset>
    </form>
  );
};
