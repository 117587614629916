import React from 'react';
import {Helmet} from 'react-helmet';

import {getCompanyName} from 'util/getCompanyName';

import LobiboxFavicon16 from '../../assets/images/favicons/Lobibox-favicon16.png';
import LobiboxFavicon24 from '../../assets/images/favicons/Lobibox-favicon24.png';
import LobiboxFavicon32 from '../../assets/images/favicons/Lobibox-favicon32.png';
import CommunikeyFavicon16 from '../../assets/images/favicons/Communikey-favicon16.png';
import CommunikeyFavicon24 from '../../assets/images/favicons/Communikey-favicon24.png';
import CommunikeyFavicon32 from '../../assets/images/favicons/Communikey-favicon32.png';

const favicons = {
  Lobibox: {icon16: LobiboxFavicon16, icon24: LobiboxFavicon24, icon32: LobiboxFavicon32},
  Communikey: {icon16: CommunikeyFavicon16, icon24: CommunikeyFavicon24, icon32: CommunikeyFavicon32}
};

export default function DocumentHead() {

  const companyName = getCompanyName();
  return (
    <Helmet>
      <title>{companyName} - Admin Dashboard</title>
      <meta name="apple-mobile-web-app-title" content={`${companyName} - Admin Dashboard`} />
      <link rel="icon" type="image/png" sizes="16x16" id="favicon" href={favicons[companyName].icon16} />
      <link rel="icon" type="image/png" sizes="24x24" id="favicon" href={favicons[companyName].icon24} />
      <link rel="icon" type="image/png" sizes="32x32" id="favicon" href={favicons[companyName].icon32} />
      <link rel="apple-touch-icon-precomposed" sizes="16x16" id="favicon" href={favicons[companyName].icon16} />
      <link rel="apple-touch-icon-precomposed" sizes="24x24" id="favicon" href={favicons[companyName].icon24} />
      <link rel="apple-touch-icon-precomposed" sizes="32x32" id="favicon" href={favicons[companyName].icon32} />
    </Helmet>
  );
}
