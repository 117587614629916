import React, {useEffect} from 'react';
import IntlMessages from 'util/IntlMessages';
import { Button } from '@material-ui/core';

import { useSelector } from 'react-redux';
import LBPasswordInput from '../../components/PasswordInput';

export const Form = (props) => {
  const {
    values: {
      password,
      passwordConfirm
    },
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    isValid,
    validateForm
  } = props;

  const locale = useSelector(state => state.auth.locale);

  useEffect(() => {
    validateForm();
  }, [validateForm, locale]);


  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };


  return (
    <form onSubmit={handleSubmit}>
      <LBPasswordInput
        id="adornment-password"
        error={touched.password && Boolean(errors.password)}
        fullWidth
        className="mt-0 mb-4"
        name="password"
        value={password}
        onChange={change.bind(null, 'password')}
        label={<IntlMessages id="appModule.ResetPassword.pass" />}
        helperText={touched.password ? errors.password : ''}
        />
      <LBPasswordInput
        id="adornment-confirm-password"
        error={touched.passwordConfirm && Boolean(errors.passwordConfirm)}
        fullWidth
        className="mt-0 mb-4"
        name="passwordConfirm"
        value={passwordConfirm}
        onChange={change.bind(null, 'passwordConfirm')}
        label={<IntlMessages id="appModule.ResetPassword.confirmPass" />}
        helperText={touched.passwordConfirm ? errors.passwordConfirm : ''}
        />
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid}>
          <IntlMessages id="appModule.ResetPassword.change" />
        </Button>
      </div>
    </form>
  );
};
