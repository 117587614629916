export const companiesNames = { LOBIBOX: 'Lobibox', COMMUNIKEY: 'Communikey' };
export const companiesInformation = {
  [companiesNames.LOBIBOX]: {
    email: 'support@lobibox.com',
    manualLink: 'https://lobibox.com/support/manuals/'
  },
  [companiesNames.COMMUNIKEY]: {
    email: 'support@communikey.com',
    manualLink: 'https://communikey.io'
  }
};
