import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import asyncComponent from 'util/asyncComponent';


export default function PrivateRoute(props) {

  const isAdminsPath = props.path.includes('/admins/');

  const isSuperuser = useSelector(state => state.auth.isSuperuser);
  const isOwner = useSelector(state => state.company.currentCompany.isOwner);
  const isFullAccess = useSelector(state => state.company.currentCompany.isFullAccess);

  if ((isFullAccess || isSuperuser) && !isAdminsPath) { return <Route {...props} />; }
  if (isAdminsPath && (isOwner || isSuperuser)) { return <Route {...props} />; }

  return (
    <Route component={asyncComponent(() => import('components/Error404'))} />
  );

}
