/* eslint-disable camelcase */
import React from 'react';
import { delay } from 'lodash';
import IntlMessages from 'util/IntlMessages';


import API from '../../services/api';
import CompanyLogo from '../../components/CompanyLogo/CompanyLogo';

const SCREEN_TITLE = <IntlMessages id="containersEmail.confirmation" />;
const REDIRECT_DELAY = 2500;

class EmailConfirm extends React.Component {
  constructor() {
    super();
    this.state = {
      showDetail: '',
      showError: ''
    };
  }

  componentDidMount() {
    const { uid, token } = this.props.match.params;
    const { history } = this.props;

    API.post('/user/confirm-email/', {
      uid, token
    }).then((res) => {
      const {detail, next_step, password_set_link} = res.data;
      this.setState({showDetail: detail});

      if (next_step === 'set_password') {
        window.location.assign(password_set_link);
      }

      if (next_step === 'login') {
        delay(() => { history.push('/signin'); }, REDIRECT_DELAY);
      }
    }).catch((e) => {
      const errorMsg = e.response.data.detail || e.response.data.non_field_errors[0];
      this.setState({showError: errorMsg});
    });
  }

  render() {
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <CompanyLogo big />
          </div>

          <div className="app-login-content">
            <div className="app-login-header">
              <h1>{SCREEN_TITLE}</h1>
            </div>


            <div className="app-login-form">
              {this.state.showError && <div>{this.state.showError}</div>}
              {this.state.showDetail && (
              <>
                <div>{this.state.showDetail}</div>
                <div><IntlMessages id="containersEmail.redirected" /></div>
              </>
              )}
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default EmailConfirm;
