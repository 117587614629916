import React from 'react';
import { connect } from 'react-redux';

import { hideMessage, showAuthLoader, setupPassword } from 'store/actions/Auth';
import PasswordConfirm from './PasswordConfirm';

class PasswordSetup extends React.Component {
  submitValues = (password, passwordConfirm) => {
    const { uid, token } = this.props.match.params;

    this.props.showAuthLoader();
    this.props.setupPassword({
      password,
      passwordConfirm,
      uid,
      token
    });
  };

  render() {
    const {
      showMessage,
      loader,
      alertMessage,
      hideMessage,
    } = this.props;

    return (
      <PasswordConfirm
        passwordType="setup"
        showMessage={showMessage}
        loader={loader}
        alertMessage={alertMessage}
        hideMessage={hideMessage}
        submitValues={this.submitValues}
      />
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {
    loader, alertMessage, showMessage, authUser, infoMessage
  } = auth;
  return {
    loader, alertMessage, showMessage, authUser, infoMessage
  };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  setupPassword
})(PasswordSetup);
