import API from 'services/api';
import { catchError } from 'services/catchError';

const boolPattern = /^is_/;

const isBool = name => boolPattern.test(name);

const determineSortOrder = (sortFieldName, queryDirection) => {

  if (queryDirection === 'desc') {
    return `${isBool(sortFieldName) ? '' : '-'}${sortFieldName}`;
  }
  if (queryDirection === 'asc') {
    return `${isBool(sortFieldName) ? '-' : ''}${sortFieldName}`;
  }

};

export const composeQueryParams = (query = {}) => {
  const params = {};
  const prepareSearchString = string => string.split(',').join('\\,');
  params.page = (query.hasOwnProperty('page') && query.page + 1) || 1;
  params.is_active = (query.hasOwnProperty('isActive') && query.isActive);
  params.search = (query.hasOwnProperty('search') && prepareSearchString(query.search)) || '';
  params.ordering = (query.hasOwnProperty('orderBy')
    && query.orderBy
    && query.orderBy.hasOwnProperty('field')
    && query.orderBy.field) || '';

  if (params.ordering.length > 0) {
    params.ordering = determineSortOrder(params.ordering, query.orderDirection);
  }

  if (query.hasOwnProperty('filters') && (query.filters.length > 0)) {
    query.filters.forEach((item) => {
      params[item.column.field] = Array.isArray(item.value) ? item.value.join() : item.value;
    });
  }

  return params;
};

export const composeQueryParamsForReports = (query = {}, timePeriod, dateFrom, dateTo) => {
  const prepareSearchString = string => string.split(',').join('\\,');
  const params = {};
  params.page = (query.hasOwnProperty('page') && query.page + 1) || 1;
  params.search = (query.hasOwnProperty('search') && prepareSearchString(query.search)) || '';

  if (timePeriod === 'custom' && dateFrom && dateTo) {
    params.from = dateFrom;
    params.to = dateTo;
  }

  if (timePeriod) {
    params.date = timePeriod || 'today';
  }

  params.ordering = (query.hasOwnProperty('orderBy')
    && query.orderBy
    && query.orderBy.hasOwnProperty('field')
    && query.orderBy.field) || '';


  if (params.ordering.length > 0) {
    params.ordering = determineSortOrder(params.ordering, query.orderDirection);
  }

  if (query.filters.length > 0) {
    query.filters.forEach((item) => {
      params[item.column.field] = Array.isArray(item.value) ? item.value.join() : item.value;
    });
  }

  return params;
};

// todo compose common request?

export const fetchBuildings = (companyId, page = 1, all = false, props) => {
  const paramsObj = {};
  paramsObj.page = page;
  if (all) {
    paramsObj.all = true;
  }
  return API.get(`companies/${companyId}/buildings/`, {
    params: paramsObj
  })
    .then(buildings => buildings.data)
    .catch(error => catchError(error, props));
};

export const fetchAccessLevels = (companyId, page = 1, all = false, props) => {
  const paramsObj = {};
  paramsObj.page = page;
  if (all) {
    paramsObj.all = true;
  }
  return API.get(`companies/${companyId}/access_levels/`, {
    params: paramsObj
  })
    .then(al => al.data)
    .catch(error => catchError(error, props));
};

export const fetchVisitorUnits = (companyId, page = 1, all = false, props) => {
  const paramsObj = {};
  paramsObj.page = page;
  if (all) {
    paramsObj.all = true;
  }
  return API.get(`companies/${companyId}/visitor_units/`, {
    params: paramsObj
  })
    .then(al => al.data)
    .catch(error => catchError(error, props));
};

export const fetchTenants = (companyId, page = 1, all = false, onlyActive = true, searchParams, props) => {
  const paramsObj = {};
  paramsObj.page = page;
  if (all) {
    paramsObj.all = true;
  }
  if (searchParams) {
    paramsObj.search = searchParams;
  }

  return API.get(`companies/${companyId}/tenants_all/`, {
    params: paramsObj
  })
    .then((tenants) => {
      if (onlyActive) {
        return tenants.data.filter(tenant => tenant.is_active);
      }
      return tenants.data;
    })
    .catch((error) => {
      catchError(error, props);
    });
};

export const fetchAllTenants = (companyId, props) => API.get(`companies/${companyId}/tenants_all/`, {
  params: { all: true }
})
  .then(tenants => tenants.data)
  .catch(error => catchError(error, props));

export const fetchCompanyAdmins = (companyId, props) => API.get(`companies/${companyId}/admin/`)
  .then(companyAdmins => companyAdmins.data)
  .catch(error => catchError(error, props));

export const fetchDefaultVuSettings = () => API.get('/companies/visitor_units/default-settings/')
  .then(vuSettings => vuSettings.data)
  .catch(error => catchError(error));

export const fetchSecurityMembers = (companyId, all = true, props) => API.get(`companies/${companyId}/tenants/security/?all=true`)
  .then(tenants => tenants.data)
  .catch(error => catchError(error, props));

export const fetchAvailableVUnitsForTenant = (companyId, tenantId) => API.get(`companies/${companyId}/tenants/${tenantId}/visitor_units/`)
  .then(({ data }) => data.call_transaction_list)
  .catch(error => catchError(error));

export const fetchAdminSecurityMembers = (companyId, companyAdminId, all = true, props) => API.get(`companies/${companyId}/tenants/security/?all=true&company_admin=${companyAdminId}`)
  .then(tenants => tenants.data)
  .catch(error => catchError(error, props));

export const fetchEventsFilter = (companyId, name, props) => API.get(`companies/${companyId}/reports/event_filter/?events=${name}`)
  .catch(error => catchError(error, props));
