import React from 'react';
import {connect} from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'util/IntlMessages';
import { NavLink } from 'react-router-dom';

import { userSignOut } from '../../store/actions/Auth';

class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = (event) => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };

  getFullName = (firstName, lastName) => `${lastName} ${firstName}`;

  handleLogOut = () => {
    this.handleRequestClose();
    localStorage.removeItem('token');
    this.props.userSignOut();
  };

  render() {
    const { firstName, lastName } = this.props;
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>
            {this.getFullName(firstName, lastName)}
            <i
              className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
        >
          <MenuItem onClick={this.handleRequestClose}>
            <NavLink to="/app/profile">
              <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
              <span className="nav-text">
                <IntlMessages id="userInfoPopup.profile" />
                {''}
              </span>
            </NavLink>
          </MenuItem>
          <MenuItem onClick={this.handleLogOut}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
            <IntlMessages id="userInfoPopup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {
    email, firstName, lastName, locale
  } = auth;
  return {
    locale, email, firstName, lastName
  };
};
export default connect(mapStateToProps, {userSignOut})(UserInfo);
