
import frMessages from '../locales/fr_FR.json';
import frCustomMessages from '../locales/fr_FR_custom.json';

const frLang = {
  messages: {
    ...frMessages,
    ...frCustomMessages,
  },
  locale: 'fr',
};
export default frLang;
