
import saMessages from '../locales/it_IT.json';

const saLang = {
  messages: {
    ...saMessages
  },
  locale: 'it-IT',
};
export default saLang;
