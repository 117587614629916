import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import Companies from './Companies';
import Settings from './Settings';
import Auth from './Auth';
import Integrations from './Integrations';

export default history => combineReducers({
  router: connectRouter(history),
  company: Companies,
  settings: Settings,
  auth: Auth,
  integrations: Integrations
});
