import { createIntl } from 'react-intl';
import AppLocale from './index';
import { store } from '../MainApp';


const getIntl = () => {
  const currentAppLocale = AppLocale[store.getState().auth.locale];
  const locale = currentAppLocale.locale;
  const messages = currentAppLocale.messages;

  return createIntl({
    locale,
    messages,
  });
};

export default getIntl;
