/* eslint-disable no-underscore-dangle */
import {put, takeEvery} from 'redux-saga/effects';
import { fetchIntegrations } from 'services/apiRoutes';
import { fetchAllIntegrationsSuccess } from '../actions';
import { FETCH_ALL_INTEGRATIONS } from '../ActionTypes';

export function* _fetchIntegrations({ payload }) {
  try {
    const {data} = yield fetchIntegrations(payload);
    yield put(fetchAllIntegrationsSuccess(data));
  } catch (error) {
    console.log(error.response);
  }
}

export function* watchSaga() {
  yield takeEvery(FETCH_ALL_INTEGRATIONS, _fetchIntegrations);
}

export default function* rootSaga() {
  yield watchSaga();
}
