import API from 'services/api';
import { composeQueryParams } from 'services/apiRequests';
import { catchError } from 'services/catchError';

export const fetchAccessLevels = (companyId, query) => {
  const params = composeQueryParams(query);

  return API.get(`companies/${companyId}/access_levels/`, {
    params,
  }).then(al => al.data);
};

export const createNewAccessLevel = ({
  companyId,
  save,
  name,
  isActive,
  isDefault,
  visitorCodeLength,
}) => API.post(
  `companies/${companyId}/access_levels/`,
  {
    name,
    is_active: isActive,
    is_default: isDefault,
    visitor_code_length: visitorCodeLength.toString().length
      ? visitorCodeLength
      : null,
  },
  {
    params: {
      save,
    },
  }
);

export const updateAl = ({
  companyId,
  save,
  name,
  isActive,
  isDefault,
  accessLevelId,
  visitorCodeLength,
  canCreateQuickCode,
  quickVisitorCodePackageEnabled,
}) => {
  const data = {
    name,
    is_active: isActive,
    is_default: isDefault,
    visitor_code_length: visitorCodeLength.toString().length
      ? visitorCodeLength
      : null,
  };

  if (canCreateQuickCode !== null && quickVisitorCodePackageEnabled) {
    data.can_create_quick_code = canCreateQuickCode;
  }

  return API.patch(
    `companies/${companyId}/access_levels/${accessLevelId}/`,
    data,
    {
      params: {
        save,
      },
    }
  );
};

export const fetchAL = (companyId, alId) => API.get(`companies/${companyId}/access_levels/${alId}/`).then(
  al => al.data
);

export const fetchPermissions = (companyId, alId) => API.get(`companies/${companyId}/access_levels/${alId}/permissions/`).then(
  permissions => permissions.data
);

export const fetchSchedules = (companyId, params) => API.get(`companies/${companyId}/schedules/`, {
  params,
}).then(schedules => schedules.data);

const getDeviceKey = type => (type === 'visitorUnits'
  ? { url: 'vu', payload: 'visitor_unit' }
  : { url: 'acb_door', payload: 'acb_door' });


export const updateRule = (
  companyId,
  alId,
  vusId,
  permission,
  deviceId,
  schedule,
  type
) => {

  const deviceKey = getDeviceKey(type);

  return API.patch(
    `companies/${companyId}/access_levels/${alId}/permissions/${vusId}/${deviceKey.url}/`,
    {
      permission,
      [deviceKey.payload]: deviceId,
      schedule,
    }
  );
};

export const createRule = (
  companyId,
  alId,
  permission,
  deviceId,
  schedule,
  type
) => {

  const deviceKey = getDeviceKey(type);

  return API.post(`companies/${companyId}/access_levels/${alId}/permissions/${deviceKey.url}/`, {
    permission,
    [deviceKey.payload]: deviceId,
    schedule,
  });
};

export const removeRule = (companyId, alId, permission, deviceId, type) => {

  const deviceKey = getDeviceKey(type);

  return API.patch(
    `companies/${companyId}/access_levels/${alId}/permissions/${deviceKey.url}/destroy/`,
    {
      permission,
      [deviceKey.payload]: deviceId,
    }
  );
};

export const removePermission = (companyId, alId, permission) => API.patch(
  `companies/${companyId}/access_levels/${alId}/permissions/destroy/`,
  {
    permission,
  }
);

export const fetchBuildings = (companyId, query) => {
  const params = composeQueryParams(query);

  return API.get(`companies/${companyId}/buildings/`, {
    params,
  }).then(buildings => buildings.data);
};

export const addBuildings = (companyId, data) => API.post(`companies/${companyId}/buildings/`, data);

export const fetchBuilding = (companyId, buildingId) => API.get(`companies/${companyId}/buildings/${buildingId}/`).then(
  building => building.data
);

export const updateBuilding = (companyId, buildingId, data) => API.patch(`companies/${companyId}/buildings/${buildingId}/`, data);

export const fetchCompanies = (query) => {
  const params = composeQueryParams(query);

  return API.get('companies/', {
    params,
  }).then(companies => companies.data);
};

export const fetchCompany = (companyId, props) => API.get(`companies/${companyId}/`)
  .then(companyResp => companyResp.data)
  .catch(error => catchError(error, props));

export const fetchCompanyAdmins = (companyId, props) => API.get(`companies/${companyId}/admin/`)
  .then(companyAdmins => companyAdmins.data)
  .catch(error => catchError(error, props));

export const updateCompany = (companyId, data) => API.patch(`companies/${companyId}/`, data, {
  headers: { 'Content-Type': 'multipart/form-data' },
});

export const fetchAdmins = (companyId, query) => {
  const params = composeQueryParams(query);

  return API.get(`companies/${companyId}/admin/`, {
    params,
  }).then(admins => admins.data);
};

export const addCompany = (companyId, data) => API.post(`companies/${companyId}/admin/`, data);

export const fetchAdmin = (companyId, AdminId) => API.get(`companies/${companyId}/admin/${AdminId}/`).then(
  admin => admin.data
);

export const editAdmin = (companyId, adminId, data) => API.patch(`companies/${companyId}/admin/${adminId}/`, data);

export const resendLinkForAdminPasswordSetUp = (companyId, adminId) => API.get(`companies/${companyId}/admin/${adminId}/password/`).then(
  resp => resp.data
);

export const fetchEvents = (companyId, data) => API.get(`companies/${companyId}/events/`, data).then(events => events.data);

export const fetchEvent = (companyId, eventId) => API.get(`companies/${companyId}/events/${eventId}/`).then(
  event => event.data
);

export const changePassword = data => API.post('user/password/change/', data);

export const fetchDoorsReports = (companyId, data) => API.get(`companies/${companyId}/reports/doors/`, data);

export const fetchErrorsReports = (companyId, data) => API.get(`companies/${companyId}/reports/errors/`, data);

export const fetchEventsReports = (companyId, data) => API.get(`companies/${companyId}/reports/events/`, data);

export const fetchMembersReports = (companyId, data) => API.get(`companies/${companyId}/reports/members/`, data);

export const fetchVisitorCodesReports = (companyId, data) => API.get(`companies/${companyId}/reports/visitor_codes/`, data);

export const createSchedule = (companyId, bodyFormData) => API.post(`companies/${companyId}/schedules/`, bodyFormData, {
  headers: { 'Content-Type': 'multipart/form-data' },
});

export const fetchSchedule = (companyId, scheduleId) => API.get(`companies/${companyId}/schedules/${scheduleId}/`).then(
  schedule => schedule.data
);

export const changeSchedule = (companyId, scheduleId, params) => API.patch(`companies/${companyId}/schedules/${scheduleId}/`, params);

export const createMember = (companyId, params) => API.post(`companies/${companyId}/tenants/`, params);

export const fetchTenant = (companyId, tenantId) => API.get(`companies/${companyId}/tenants/${tenantId}/`).then(
  tenant => tenant.data
);

// for redirect or rollover
export const fetchAvailableTenants = (companyId, tenantId, all = true) => API.get(`companies/${companyId}/tenants/${tenantId}/available/`, {
  params: {
    all,
  },
});

export const fetchAllowedDialCodesForTenant = companyId => API.get(`companies/${companyId}/tenants/dialcodes/`).then(
  resp => resp.data.dial_codes
);

export const resendLinkForTenantPasswordSetUp = (companyId, tenantId) => API.get(`companies/${companyId}/tenants/${tenantId}/password/`).then(
  resp => resp.data
);

export const revokeTenant = (companyId, tenantId) => API.post(`companies/${companyId}/tenants/${tenantId}/revoke/`).then(
  resp => resp.data
);

export const changeTenant = (companyId, tenantId, params) => API.patch(`companies/${companyId}/tenants/${tenantId}/`, params);

export const fetchTenants = (companyId, params) => API.get(`companies/${companyId}/tenants/`, {
  params,
}).then(tenants => tenants.data);

export const fetchVisitorCodes = (companyId, params) => API.get(`companies/${companyId}/visitor_codes/`, {
  params,
}).then(vc => vc.data);

export const editActiveVisitorCode = (companyId, vcId, params) => API.patch(`companies/${companyId}/visitor_codes/${vcId}/`, params);

export const createVisitorCode = (companyId, params) => API.post(`companies/${companyId}/visitor_codes/`, params);

export const editVisitorCode = (companyId, vcId, params) => API.patch(`companies/${companyId}/visitor_codes/${vcId}/`, params);

export const fetchVisitorCode = (companyId, vcId) => API.get(`companies/${companyId}/visitor_codes/${vcId}/`).then(
  vu => vu.data
);

export const fetchAllowedVisitorUnits = (companyId, vcId) => API.get(`companies/${companyId}/visitor_codes/${vcId}/vu/`).then(
  vu => vu.data
);

export const changeVisitorCodeActiveStatus = (companyId, vcId, param) => API.post(
  `companies/${companyId}/visitor_codes/${vcId}/change_status/`,
  param
);

export const fetchVisitorImages = (companyId, params) => API.get(`companies/${companyId}/visitor_images/`, {
  params,
}).then(al => al.data);

export const fetchVUs = (companyId, params) => API.get(`companies/${companyId}/visitor_units/`, {
  params,
}).then(vu => vu.data);

export const updateVU = (companyId, params) => API.post(`companies/${companyId}/visitor_units/update/`, params);

export const rebootVU = (companyId, params) => API.post(`companies/${companyId}/visitor_units/reboot/`, params);

export const addVisitorUnit = (companyId, data) => API.post(`companies/${companyId}/visitor_units/`, data);

export const fetchVU = (companyId, vuId) => API.get(`companies/${companyId}/visitor_units/${vuId}/`).then(
  vu => vu.data
);

export const fetchCardReadrsList = companyId => API.get(`companies/${companyId}/access_control_boards/card_readers/`).then(doors => doors.data);

export const fetchDoorsControllers = (companyId, params) => API.get(`companies/${companyId}/access_control_boards/`, {
  params,
}).then(doors => doors.data);

export const fetchDoors = (companyId, params) => API.get(`companies/${companyId}/access_control_boards/doors`, {
  params,
}).then(doors => doors.data);

export const fetchDoorsController = (companyId, doorsControllerId) => API.get(`companies/${companyId}/access_control_boards/${doorsControllerId}/`).then(
  doorsController => doorsController.data
);

export const addDoorController = (companyId, data) => API.post(`companies/${companyId}/access_control_boards/`, data);

export const changeDoorController = (companyId, doorControllerId, data) => API.patch(`companies/${companyId}/access_control_boards/${doorControllerId}/`, data);

export const updateDoorsControllers = (companyId, params) => API.post(`companies/${companyId}/access_control_boards/update/`, params);

export const changeVU = (companyId, vuId, data) => API.patch(`companies/${companyId}/visitor_units/${vuId}/`, data);

export const changeLanguage = language => API.patch('/user/me/', { language });

export const expireToken = () => API.post('/user/expire-token/');

export const fetchMainPage = () => API.get('/companies/app-pages/').then(res => res.data);

export const createMainPage = pages => API.post('/companies/app-pages/', { translations: pages }).then(
  res => res.data
);

export const updateMainPage = (id, pages) => API.put(`/companies/app-pages/${id}/`, { translations: pages }).then(
  res => res.data
);

export const deleteMainPage = id => API.delete(`/companies/app-pages/${id}/`).then(res => res.data);

export const fetchIntegrations = commanyId => API.get(`/companies/${commanyId}/integrations/`);

export const updateIntegrations = (commanyId, integrations) => API.put(`/companies/${commanyId}/integrations/`, integrations).then(
  res => res.data
);

export const fetchSkidataDevices = commanyId => API.get(`/companies/${commanyId}/integrations/skidata/devices/`).then(
  res => res.data
);

export const refreshSkidataDevices = commanyId => API.post(
  `/companies/${commanyId}/integrations/skidata/devices/refresh/`
).then(res => res);

export const fetchSkidataDeviceInformation = (commanyId, deviceId) => API.get(
  `/companies/${commanyId}/integrations/skidata/devices/${deviceId}/`
).then(res => res.data);

export const updateSkidataDeviceInformation = (commanyId, deviceId, data) => API.put(
  `/companies/${commanyId}/integrations/skidata/devices/${deviceId}/`,
  data
).then(res => res.data);

export const fetchSkidataSettings = commanyId => API.get(`/companies/${commanyId}/integrations/skidata/configuration/`).then(
  res => res.data
);

export const createSkidataSettings = (commanyId, data) => API.post(
  `/companies/${commanyId}/integrations/skidata/configuration/`,
  data
).then(res => res.data);

export const updateSkidataSettings = (commanyId, data) => API.put(
  `/companies/${commanyId}/integrations/skidata/configuration/`,
  data
).then(res => res.data);
